// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apie-mus-js": () => import("./../../../src/pages/apie-mus.js" /* webpackChunkName: "component---src-pages-apie-mus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontaktai-js": () => import("./../../../src/pages/kontaktai.js" /* webpackChunkName: "component---src-pages-kontaktai-js" */),
  "component---src-pages-pasiulymai-js": () => import("./../../../src/pages/pasiulymai.js" /* webpackChunkName: "component---src-pages-pasiulymai-js" */),
  "component---src-pages-pasiulymai-wp-post-slug-js": () => import("./../../../src/pages/pasiulymai/{wpPost.slug}.js" /* webpackChunkName: "component---src-pages-pasiulymai-wp-post-slug-js" */),
  "component---src-pages-paslaugos-js": () => import("./../../../src/pages/paslaugos.js" /* webpackChunkName: "component---src-pages-paslaugos-js" */),
  "component---src-pages-privatumo-politika-js": () => import("./../../../src/pages/privatumo-politika.js" /* webpackChunkName: "component---src-pages-privatumo-politika-js" */)
}

